@mixin ng2-archwizard-theme($color) {
  aw-wizard ul.steps-indicator li.current div.step-indicator,
  aw-wizard ul.steps-indicator li.editing div.step-indicator {
    border-color: $color !important;
    color: $color !important;
  }

  aw-wizard ul.steps-indicator li.done:not(.editing) div.step-indicator {
    border-color: #E6E6E6 !important;
    color: #E6E6E6 !important;
  }
}
