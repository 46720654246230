/*
 *  Usage:
 *
      <div class="sk-rotating-plane"></div>
 *
 */
.sk-rotating-plane {
  width: 30px;
  height: 30px;
  background-color: #ccc;
  margin: 40px auto;
  animation: sk-rotatePlane 1.2s infinite ease-in-out;
}

@keyframes sk-rotatePlane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/*
 *  Usage:
 *
      <div class="sk-double-bounce">
        <div class="sk-child sk-double-bounce1"></div>
        <div class="sk-child sk-double-bounce2"></div>
      </div>
 *
 */
.sk-double-bounce {
  width: 30px;
  height: 30px;
  position: relative;
  margin: 40px auto;
}

.sk-double-bounce .sk-child {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ccc;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-doubleBounce 2.0s infinite ease-in-out;
}

.sk-double-bounce .sk-double-bounce2 {
  animation-delay: -1.0s;
}

@keyframes sk-doubleBounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

/*
 *  Usage:
 *
      <div class="sk-wave">
        <div class="sk-rect sk-rect1"></div>
        <div class="sk-rect sk-rect2"></div>
        <div class="sk-rect sk-rect3"></div>
        <div class="sk-rect sk-rect4"></div>
        <div class="sk-rect sk-rect5"></div>
      </div>
 *
 */
.sk-wave {
  margin: 40px auto;
  width: 37.5px;
  height: 30px;
  text-align: center;
  font-size: 10px;
}

.sk-wave .sk-rect {
  background-color: #ccc;
  height: 100%;
  width: 6px;
  display: inline-block;
  animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}

.sk-wave .sk-rect1 {
  animation-delay: -1.2s;
}

.sk-wave .sk-rect2 {
  animation-delay: -1.1s;
}

.sk-wave .sk-rect3 {
  animation-delay: -1s;
}

.sk-wave .sk-rect4 {
  animation-delay: -0.9s;
}

.sk-wave .sk-rect5 {
  animation-delay: -0.8s;
}

@keyframes sk-waveStretchDelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

/*
 *  Usage:
 *
      <div class="sk-wandering-cubes">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
      </div>
 *
 */
.sk-wandering-cubes {
  margin: 40px auto;
  width: 30px;
  height: 30px;
  position: relative;
}

.sk-wandering-cubes .sk-cube {
  background-color: #ccc;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both;
}

.sk-wandering-cubes .sk-cube2 {
  animation-delay: -0.9s;
}

@keyframes sk-wanderingCube {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: translateX(30px) rotate(-90deg) scale(0.5);
  }
  50% {
    /* Hack to make FF rotate in the right direction */
    transform: translateX(30px) translateY(30px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(30px) translateY(30px) rotate(-180deg);
  }
  75% {
    transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/*
 *  Usage:
 *
      <div class="sk-spinner sk-spinner-pulse"></div>
 *
 */
.sk-spinner-pulse {
  width: 30px;
  height: 30px;
  margin: 40px auto;
  background-color: #ccc;
  border-radius: 100%;
  animation: sk-pulseScaleOut 1.0s infinite ease-in-out;
}

@keyframes sk-pulseScaleOut {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/*
 *  Usage:
 *
      <div class="sk-chasing-dots">
        <div class="sk-child sk-dot1"></div>
        <div class="sk-child sk-dot2"></div>
      </div>
 *
 */
.sk-chasing-dots {
  margin: 40px auto;
  width: 30px;
  height: 30px;
  position: relative;
  text-align: center;
  animation: sk-chasingDotsRotate 2s infinite linear;
}

.sk-chasing-dots .sk-child {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #ccc;
  border-radius: 100%;
  animation: sk-chasingDotsBounce 2s infinite ease-in-out;
}

.sk-chasing-dots .sk-dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}

@keyframes sk-chasingDotsRotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chasingDotsBounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

/*
 *  Usage:
 *
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
 *
 */
.sk-three-bounce {
  margin: 40px auto;
  width: 60px;
  text-align: center;
}

.sk-three-bounce .sk-child {
  width: 15px;
  height: 15px;
  background-color: #ccc;
  border-radius: 100%;
  display: inline-block;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
  animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-three-bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/*
 *  Usage:
 *
      <div class="sk-circle">
        <div class="sk-circle1 sk-child"></div>
        <div class="sk-circle2 sk-child"></div>
        <div class="sk-circle3 sk-child"></div>
        <div class="sk-circle4 sk-child"></div>
        <div class="sk-circle5 sk-child"></div>
        <div class="sk-circle6 sk-child"></div>
        <div class="sk-circle7 sk-child"></div>
        <div class="sk-circle8 sk-child"></div>
        <div class="sk-circle9 sk-child"></div>
        <div class="sk-circle10 sk-child"></div>
        <div class="sk-circle11 sk-child"></div>
        <div class="sk-circle12 sk-child"></div>
      </div>
 *
 */
.sk-circle {
  margin: 40px auto;
  width: 30px;
  height: 30px;
  position: relative;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #ccc;
  border-radius: 100%;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  animation-delay: -0.1s;
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/*
 *  Usage:
 *
      <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
      </div>
 *
 */
.sk-cube-grid {
  width: 30px;
  height: 30px;
  margin: 40px auto;
  /*
   * Spinner positions
   * 1 2 3
   * 4 5 6
   * 7 8 9
   */
}

.sk-cube-grid .sk-cube {
  width: 33.33%;
  height: 33.33%;
  background-color: #ccc;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  animation-delay: 0.0s;
}

.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}

/*
 *  Usage:
 *
      <div class="sk-fading-circle">
        <div class="sk-circle1 sk-circle"></div>
        <div class="sk-circle2 sk-circle"></div>
        <div class="sk-circle3 sk-circle"></div>
        <div class="sk-circle4 sk-circle"></div>
        <div class="sk-circle5 sk-circle"></div>
        <div class="sk-circle6 sk-circle"></div>
        <div class="sk-circle7 sk-circle"></div>
        <div class="sk-circle8 sk-circle"></div>
        <div class="sk-circle9 sk-circle"></div>
        <div class="sk-circle10 sk-circle"></div>
        <div class="sk-circle11 sk-circle"></div>
        <div class="sk-circle12 sk-circle"></div>
      </div>
 *
 */
.sk-fading-circle {
  margin: 40px auto;
  width: 30px;
  height: 30px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #ccc;
  border-radius: 100%;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  animation-delay: -0.1s;
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

/*
 *  Usage:
 *
      <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
      </div>
 *
 */
.sk-folding-cube {
  margin: 40px auto;
  width: 30px;
  height: 30px;
  position: relative;
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  animation-delay: 0.9s;
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.sk-wave {
  width: 40px;
  white-space: nowrap;
}

.sk-fading-circle .sk-circle {
  margin-top: 0;
  margin-bottom: 0;
}
