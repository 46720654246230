.text-twitter {
  color: #1DA1F2 !important;
}

a.text-twitter:hover, a.text-twitter:focus {
  color: #0b76b8 !important;
}

.bg-twitter {
  background-color: #1DA1F2 !important;
}

a.bg-twitter:hover, a.bg-twitter:focus {
  background-color: #1c99e6 !important;
}

.bg-twitter-dark {
  background-color: #1a91da !important;
}

a.bg-twitter-dark:hover, a.bg-twitter-dark:focus {
  background-color: #198acf !important;
}

.bg-twitter-darker {
  background-color: #1989ce !important;
}

a.bg-twitter-darker:hover, a.bg-twitter-darker:focus {
  background-color: #1882c4 !important;
}

.btn-twitter {
  background: #1DA1F2;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-twitter:hover {
  background: #28a6f3;
  color: #fff;
}

.btn-twitter:hover, .btn-twitter:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-twitter .badge {
  background: #fff;
  color: #1DA1F2;
}

.btn-twitter .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-twitter:active,
.btn-twitter.active,
.show > .btn-twitter.dropdown-toggle {
  background: #3aadf4;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-twitter.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #1DA1F2;
}

.btn-twitter.md-btn-flat:focus, .btn-twitter.md-btn-flat.focus {
  background: rgba(29, 161, 242, 0.1);
}

.btn-twitter.md-btn-flat .waves-ripple {
  background: rgba(29, 161, 242, 0.3) !important;
}

.btn-twitter.md-btn-flat:active,
.btn-twitter.md-btn-flat.active,
.show > .btn-twitter.md-btn-flat.dropdown-toggle {
  background: rgba(29, 161, 242, 0.15);
}

.btn-twitter.disabled,
.btn-twitter:disabled {
  background: #1DA1F2 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-twitter.md-btn-flat.disabled,
.btn-twitter.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #1DA1F2 !important;
}

.btn-outline-twitter {
  background: transparent;
  box-shadow: 0 0 0 1px #1DA1F2 inset;
  color: #1DA1F2;
}

.btn-outline-twitter:hover, .btn-outline-twitter:focus {
  background: #1DA1F2;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #1DA1F2 inset !important;
  color: #1DA1F2 !important;
}

.btn-outline-twitter:active,
.btn-outline-twitter.active,
.show > .btn-outline-twitter.dropdown-toggle {
  background: #28a6f3;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-twitter .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-twitter .badge {
  background: #1DA1F2;
  border-color: #1DA1F2;
  color: #fff;
}

.btn-outline-twitter:hover .badge,
.btn-outline-twitter:focus .badge,
.btn-outline-twitter:active .badge,
.btn-outline-twitter.active .badge,
.show > .btn-outline-twitter.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #1DA1F2;
}

.text-google {
  color: #D1523F !important;
}

a.text-google:hover, a.text-google:focus {
  color: #9e3526 !important;
}

.bg-google {
  background-color: #D1523F !important;
}

a.bg-google:hover, a.bg-google:focus {
  background-color: #c74e3c !important;
}

.bg-google-dark {
  background-color: #bc4a39 !important;
}

a.bg-google-dark:hover, a.bg-google-dark:focus {
  background-color: #b34636 !important;
}

.bg-google-darker {
  background-color: #b24636 !important;
}

a.bg-google-darker:hover, a.bg-google-darker:focus {
  background-color: #a94333 !important;
}

.btn-google {
  background: #D1523F;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-google:hover {
  background: #d35b49;
  color: #fff;
}

.btn-google:hover, .btn-google:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-google .badge {
  background: #fff;
  color: #D1523F;
}

.btn-google .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-google:active,
.btn-google.active,
.show > .btn-google.dropdown-toggle {
  background: #d76858;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-google.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #D1523F;
}

.btn-google.md-btn-flat:focus, .btn-google.md-btn-flat.focus {
  background: rgba(209, 82, 63, 0.1);
}

.btn-google.md-btn-flat .waves-ripple {
  background: rgba(209, 82, 63, 0.3) !important;
}

.btn-google.md-btn-flat:active,
.btn-google.md-btn-flat.active,
.show > .btn-google.md-btn-flat.dropdown-toggle {
  background: rgba(209, 82, 63, 0.15);
}

.btn-google.disabled,
.btn-google:disabled {
  background: #D1523F !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-google.md-btn-flat.disabled,
.btn-google.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #D1523F !important;
}

.btn-outline-google {
  background: transparent;
  box-shadow: 0 0 0 1px #D1523F inset;
  color: #D1523F;
}

.btn-outline-google:hover, .btn-outline-google:focus {
  background: #D1523F;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-google.disabled, .btn-outline-google:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #D1523F inset !important;
  color: #D1523F !important;
}

.btn-outline-google:active,
.btn-outline-google.active,
.show > .btn-outline-google.dropdown-toggle {
  background: #d35b49;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-google .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-google .badge {
  background: #D1523F;
  border-color: #D1523F;
  color: #fff;
}

.btn-outline-google:hover .badge,
.btn-outline-google:focus .badge,
.btn-outline-google:active .badge,
.btn-outline-google.active .badge,
.show > .btn-outline-google.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #D1523F;
}

.text-facebook {
  color: #3B5998 !important;
}

a.text-facebook:hover, a.text-facebook:focus {
  color: #263961 !important;
}

.bg-facebook {
  background-color: #3B5998 !important;
}

a.bg-facebook:hover, a.bg-facebook:focus {
  background-color: #385590 !important;
}

.bg-facebook-dark {
  background-color: #355089 !important;
}

a.bg-facebook-dark:hover, a.bg-facebook-dark:focus {
  background-color: #324c82 !important;
}

.bg-facebook-darker {
  background-color: #324c81 !important;
}

a.bg-facebook-darker:hover, a.bg-facebook-darker:focus {
  background-color: #30487b !important;
}

.btn-facebook {
  background: #3B5998;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-facebook:hover {
  background: #45619d;
  color: #fff;
}

.btn-facebook:hover, .btn-facebook:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-facebook .badge {
  background: #fff;
  color: #3B5998;
}

.btn-facebook .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-facebook:active,
.btn-facebook.active,
.show > .btn-facebook.dropdown-toggle {
  background: #546fa5;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-facebook.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #3B5998;
}

.btn-facebook.md-btn-flat:focus, .btn-facebook.md-btn-flat.focus {
  background: rgba(59, 89, 152, 0.1);
}

.btn-facebook.md-btn-flat .waves-ripple {
  background: rgba(59, 89, 152, 0.3) !important;
}

.btn-facebook.md-btn-flat:active,
.btn-facebook.md-btn-flat.active,
.show > .btn-facebook.md-btn-flat.dropdown-toggle {
  background: rgba(59, 89, 152, 0.15);
}

.btn-facebook.disabled,
.btn-facebook:disabled {
  background: #3B5998 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-facebook.md-btn-flat.disabled,
.btn-facebook.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #3B5998 !important;
}

.btn-outline-facebook {
  background: transparent;
  box-shadow: 0 0 0 1px #3B5998 inset;
  color: #3B5998;
}

.btn-outline-facebook:hover, .btn-outline-facebook:focus {
  background: #3B5998;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #3B5998 inset !important;
  color: #3B5998 !important;
}

.btn-outline-facebook:active,
.btn-outline-facebook.active,
.show > .btn-outline-facebook.dropdown-toggle {
  background: #45619d;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-facebook .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-facebook .badge {
  background: #3B5998;
  border-color: #3B5998;
  color: #fff;
}

.btn-outline-facebook:hover .badge,
.btn-outline-facebook:focus .badge,
.btn-outline-facebook:active .badge,
.btn-outline-facebook.active .badge,
.show > .btn-outline-facebook.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #3B5998;
}

.text-pinterest {
  color: #bd121c !important;
}

a.text-pinterest:hover, a.text-pinterest:focus {
  color: #770b12 !important;
}

.bg-pinterest {
  background-color: #bd121c !important;
}

a.bg-pinterest:hover, a.bg-pinterest:focus {
  background-color: #b4111b !important;
}

.bg-pinterest-dark {
  background-color: #aa1019 !important;
}

a.bg-pinterest-dark:hover, a.bg-pinterest-dark:focus {
  background-color: #a20f18 !important;
}

.bg-pinterest-darker {
  background-color: #a10f18 !important;
}

a.bg-pinterest-darker:hover, a.bg-pinterest-darker:focus {
  background-color: #990e17 !important;
}

.btn-pinterest {
  background: #bd121c;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-pinterest:hover {
  background: #c01e27;
  color: #fff;
}

.btn-pinterest:hover, .btn-pinterest:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-pinterest .badge {
  background: #fff;
  color: #bd121c;
}

.btn-pinterest .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-pinterest:active,
.btn-pinterest.active,
.show > .btn-pinterest.dropdown-toggle {
  background: #c6313a;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-pinterest.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #bd121c;
}

.btn-pinterest.md-btn-flat:focus, .btn-pinterest.md-btn-flat.focus {
  background: rgba(189, 18, 28, 0.1);
}

.btn-pinterest.md-btn-flat .waves-ripple {
  background: rgba(189, 18, 28, 0.3) !important;
}

.btn-pinterest.md-btn-flat:active,
.btn-pinterest.md-btn-flat.active,
.show > .btn-pinterest.md-btn-flat.dropdown-toggle {
  background: rgba(189, 18, 28, 0.15);
}

.btn-pinterest.disabled,
.btn-pinterest:disabled {
  background: #bd121c !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-pinterest.md-btn-flat.disabled,
.btn-pinterest.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #bd121c !important;
}

.btn-outline-pinterest {
  background: transparent;
  box-shadow: 0 0 0 1px #bd121c inset;
  color: #bd121c;
}

.btn-outline-pinterest:hover, .btn-outline-pinterest:focus {
  background: #bd121c;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-pinterest.disabled, .btn-outline-pinterest:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #bd121c inset !important;
  color: #bd121c !important;
}

.btn-outline-pinterest:active,
.btn-outline-pinterest.active,
.show > .btn-outline-pinterest.dropdown-toggle {
  background: #c01e27;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-pinterest .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-pinterest .badge {
  background: #bd121c;
  border-color: #bd121c;
  color: #fff;
}

.btn-outline-pinterest:hover .badge,
.btn-outline-pinterest:focus .badge,
.btn-outline-pinterest:active .badge,
.btn-outline-pinterest.active .badge,
.show > .btn-outline-pinterest.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #bd121c;
}

.text-instagram {
  color: #000 !important;
}

a.text-instagram:hover, a.text-instagram:focus {
  color: black !important;
}

.bg-instagram {
  background-color: #000 !important;
}

a.bg-instagram:hover, a.bg-instagram:focus {
  background-color: black !important;
}

.bg-instagram-dark {
  background-color: black !important;
}

a.bg-instagram-dark:hover, a.bg-instagram-dark:focus {
  background-color: black !important;
}

.bg-instagram-darker {
  background-color: black !important;
}

a.bg-instagram-darker:hover, a.bg-instagram-darker:focus {
  background-color: black !important;
}

.btn-instagram {
  background: #000;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-instagram:hover {
  background: #0d0d0d;
  color: #fff;
}

.btn-instagram:hover, .btn-instagram:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-instagram .badge {
  background: #fff;
  color: #000;
}

.btn-instagram .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-instagram:active,
.btn-instagram.active,
.show > .btn-instagram.dropdown-toggle {
  background: #212121;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-instagram.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #000;
}

.btn-instagram.md-btn-flat:focus, .btn-instagram.md-btn-flat.focus {
  background: rgba(0, 0, 0, 0.1);
}

.btn-instagram.md-btn-flat .waves-ripple {
  background: rgba(0, 0, 0, 0.3) !important;
}

.btn-instagram.md-btn-flat:active,
.btn-instagram.md-btn-flat.active,
.show > .btn-instagram.md-btn-flat.dropdown-toggle {
  background: rgba(0, 0, 0, 0.15);
}

.btn-instagram.disabled,
.btn-instagram:disabled {
  background: #000 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-instagram.md-btn-flat.disabled,
.btn-instagram.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #000 !important;
}

.btn-outline-instagram {
  background: transparent;
  box-shadow: 0 0 0 1px #000 inset;
  color: #000;
}

.btn-outline-instagram:hover, .btn-outline-instagram:focus {
  background: #000;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-instagram.disabled, .btn-outline-instagram:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #000 inset !important;
  color: #000 !important;
}

.btn-outline-instagram:active,
.btn-outline-instagram.active,
.show > .btn-outline-instagram.dropdown-toggle {
  background: #0d0d0d;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-instagram .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-instagram .badge {
  background: #000;
  border-color: #000;
  color: #fff;
}

.btn-outline-instagram:hover .badge,
.btn-outline-instagram:focus .badge,
.btn-outline-instagram:active .badge,
.btn-outline-instagram.active .badge,
.show > .btn-outline-instagram.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #000;
}

.text-flickr {
  color: #ff0084 !important;
}

a.text-flickr:hover, a.text-flickr:focus {
  color: #b3005c !important;
}

.bg-flickr {
  background-color: #ff0084 !important;
}

a.bg-flickr:hover, a.bg-flickr:focus {
  background-color: #f2007d !important;
}

.bg-flickr-dark {
  background-color: #e60077 !important;
}

a.bg-flickr-dark:hover, a.bg-flickr-dark:focus {
  background-color: #db0071 !important;
}

.bg-flickr-darker {
  background-color: #d90070 !important;
}

a.bg-flickr-darker:hover, a.bg-flickr-darker:focus {
  background-color: #ce006a !important;
}

.btn-flickr {
  background: #ff0084;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-flickr:hover {
  background: #ff0d8a;
  color: #fff;
}

.btn-flickr:hover, .btn-flickr:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-flickr .badge {
  background: #fff;
  color: #ff0084;
}

.btn-flickr .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-flickr:active,
.btn-flickr.active,
.show > .btn-flickr.dropdown-toggle {
  background: #ff2194;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-flickr.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #ff0084;
}

.btn-flickr.md-btn-flat:focus, .btn-flickr.md-btn-flat.focus {
  background: rgba(255, 0, 132, 0.1);
}

.btn-flickr.md-btn-flat .waves-ripple {
  background: rgba(255, 0, 132, 0.3) !important;
}

.btn-flickr.md-btn-flat:active,
.btn-flickr.md-btn-flat.active,
.show > .btn-flickr.md-btn-flat.dropdown-toggle {
  background: rgba(255, 0, 132, 0.15);
}

.btn-flickr.disabled,
.btn-flickr:disabled {
  background: #ff0084 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-flickr.md-btn-flat.disabled,
.btn-flickr.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #ff0084 !important;
}

.btn-outline-flickr {
  background: transparent;
  box-shadow: 0 0 0 1px #ff0084 inset;
  color: #ff0084;
}

.btn-outline-flickr:hover, .btn-outline-flickr:focus {
  background: #ff0084;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-flickr.disabled, .btn-outline-flickr:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #ff0084 inset !important;
  color: #ff0084 !important;
}

.btn-outline-flickr:active,
.btn-outline-flickr.active,
.show > .btn-outline-flickr.dropdown-toggle {
  background: #ff0d8a;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-flickr .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-flickr .badge {
  background: #ff0084;
  border-color: #ff0084;
  color: #fff;
}

.btn-outline-flickr:hover .badge,
.btn-outline-flickr:focus .badge,
.btn-outline-flickr:active .badge,
.btn-outline-flickr.active .badge,
.show > .btn-outline-flickr.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #ff0084;
}

.text-linkedin {
  color: #0077b5 !important;
}

a.text-linkedin:hover, a.text-linkedin:focus {
  color: #004569 !important;
}

.bg-linkedin {
  background-color: #0077b5 !important;
}

a.bg-linkedin:hover, a.bg-linkedin:focus {
  background-color: #0071ac !important;
}

.bg-linkedin-dark {
  background-color: #006ba3 !important;
}

a.bg-linkedin-dark:hover, a.bg-linkedin-dark:focus {
  background-color: #00669b !important;
}

.bg-linkedin-darker {
  background-color: #00659a !important;
}

a.bg-linkedin-darker:hover, a.bg-linkedin-darker:focus {
  background-color: #006092 !important;
}

.btn-linkedin {
  background: #0077b5;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-linkedin:hover {
  background: #0d7eb9;
  color: #fff;
}

.btn-linkedin:hover, .btn-linkedin:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-linkedin .badge {
  background: #fff;
  color: #0077b5;
}

.btn-linkedin .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-linkedin:active,
.btn-linkedin.active,
.show > .btn-linkedin.dropdown-toggle {
  background: #2189bf;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-linkedin.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #0077b5;
}

.btn-linkedin.md-btn-flat:focus, .btn-linkedin.md-btn-flat.focus {
  background: rgba(0, 119, 181, 0.1);
}

.btn-linkedin.md-btn-flat .waves-ripple {
  background: rgba(0, 119, 181, 0.3) !important;
}

.btn-linkedin.md-btn-flat:active,
.btn-linkedin.md-btn-flat.active,
.show > .btn-linkedin.md-btn-flat.dropdown-toggle {
  background: rgba(0, 119, 181, 0.15);
}

.btn-linkedin.disabled,
.btn-linkedin:disabled {
  background: #0077b5 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-linkedin.md-btn-flat.disabled,
.btn-linkedin.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #0077b5 !important;
}

.btn-outline-linkedin {
  background: transparent;
  box-shadow: 0 0 0 1px #0077b5 inset;
  color: #0077b5;
}

.btn-outline-linkedin:hover, .btn-outline-linkedin:focus {
  background: #0077b5;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-linkedin.disabled, .btn-outline-linkedin:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #0077b5 inset !important;
  color: #0077b5 !important;
}

.btn-outline-linkedin:active,
.btn-outline-linkedin.active,
.show > .btn-outline-linkedin.dropdown-toggle {
  background: #0d7eb9;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-linkedin .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-linkedin .badge {
  background: #0077b5;
  border-color: #0077b5;
  color: #fff;
}

.btn-outline-linkedin:hover .badge,
.btn-outline-linkedin:focus .badge,
.btn-outline-linkedin:active .badge,
.btn-outline-linkedin.active .badge,
.show > .btn-outline-linkedin.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #0077b5;
}

.text-periscope {
  color: #40a4c4 !important;
}

a.text-periscope:hover, a.text-periscope:focus {
  color: #2b758c !important;
}

.bg-periscope {
  background-color: #40a4c4 !important;
}

a.bg-periscope:hover, a.bg-periscope:focus {
  background-color: #3d9cba !important;
}

.bg-periscope-dark {
  background-color: #3a94b0 !important;
}

a.bg-periscope-dark:hover, a.bg-periscope-dark:focus {
  background-color: #378da7 !important;
}

.bg-periscope-darker {
  background-color: #368ba7 !important;
}

a.bg-periscope-darker:hover, a.bg-periscope-darker:focus {
  background-color: #33849f !important;
}

.btn-periscope {
  background: #40a4c4;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-periscope:hover {
  background: #4aa9c7;
  color: #fff;
}

.btn-periscope:hover, .btn-periscope:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-periscope .badge {
  background: #fff;
  color: #40a4c4;
}

.btn-periscope .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-periscope:active,
.btn-periscope.active,
.show > .btn-periscope.dropdown-toggle {
  background: #59b0cc;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-periscope.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #40a4c4;
}

.btn-periscope.md-btn-flat:focus, .btn-periscope.md-btn-flat.focus {
  background: rgba(64, 164, 196, 0.1);
}

.btn-periscope.md-btn-flat .waves-ripple {
  background: rgba(64, 164, 196, 0.3) !important;
}

.btn-periscope.md-btn-flat:active,
.btn-periscope.md-btn-flat.active,
.show > .btn-periscope.md-btn-flat.dropdown-toggle {
  background: rgba(64, 164, 196, 0.15);
}

.btn-periscope.disabled,
.btn-periscope:disabled {
  background: #40a4c4 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-periscope.md-btn-flat.disabled,
.btn-periscope.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #40a4c4 !important;
}

.btn-outline-periscope {
  background: transparent;
  box-shadow: 0 0 0 1px #40a4c4 inset;
  color: #40a4c4;
}

.btn-outline-periscope:hover, .btn-outline-periscope:focus {
  background: #40a4c4;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-periscope.disabled, .btn-outline-periscope:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #40a4c4 inset !important;
  color: #40a4c4 !important;
}

.btn-outline-periscope:active,
.btn-outline-periscope.active,
.show > .btn-outline-periscope.dropdown-toggle {
  background: #4aa9c7;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-periscope .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-periscope .badge {
  background: #40a4c4;
  border-color: #40a4c4;
  color: #fff;
}

.btn-outline-periscope:hover .badge,
.btn-outline-periscope:focus .badge,
.btn-outline-periscope:active .badge,
.btn-outline-periscope.active .badge,
.show > .btn-outline-periscope.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #40a4c4;
}

.text-tumblr {
  color: #35465c !important;
}

a.text-tumblr:hover, a.text-tumblr:focus {
  color: #19212b !important;
}

.bg-tumblr {
  background-color: #35465c !important;
}

a.bg-tumblr:hover, a.bg-tumblr:focus {
  background-color: #324357 !important;
}

.bg-tumblr-dark {
  background-color: #303f53 !important;
}

a.bg-tumblr-dark:hover, a.bg-tumblr-dark:focus {
  background-color: #2e3c4f !important;
}

.bg-tumblr-darker {
  background-color: #2d3c4e !important;
}

a.bg-tumblr-darker:hover, a.bg-tumblr-darker:focus {
  background-color: #2b394a !important;
}

.btn-tumblr {
  background: #35465c;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-tumblr:hover {
  background: #3f4f64;
  color: #fff;
}

.btn-tumblr:hover, .btn-tumblr:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-tumblr .badge {
  background: #fff;
  color: #35465c;
}

.btn-tumblr .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-tumblr:active,
.btn-tumblr.active,
.show > .btn-tumblr.dropdown-toggle {
  background: #4f5e71;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-tumblr.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #35465c;
}

.btn-tumblr.md-btn-flat:focus, .btn-tumblr.md-btn-flat.focus {
  background: rgba(53, 70, 92, 0.1);
}

.btn-tumblr.md-btn-flat .waves-ripple {
  background: rgba(53, 70, 92, 0.3) !important;
}

.btn-tumblr.md-btn-flat:active,
.btn-tumblr.md-btn-flat.active,
.show > .btn-tumblr.md-btn-flat.dropdown-toggle {
  background: rgba(53, 70, 92, 0.15);
}

.btn-tumblr.disabled,
.btn-tumblr:disabled {
  background: #35465c !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-tumblr.md-btn-flat.disabled,
.btn-tumblr.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #35465c !important;
}

.btn-outline-tumblr {
  background: transparent;
  box-shadow: 0 0 0 1px #35465c inset;
  color: #35465c;
}

.btn-outline-tumblr:hover, .btn-outline-tumblr:focus {
  background: #35465c;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-tumblr.disabled, .btn-outline-tumblr:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #35465c inset !important;
  color: #35465c !important;
}

.btn-outline-tumblr:active,
.btn-outline-tumblr.active,
.show > .btn-outline-tumblr.dropdown-toggle {
  background: #3f4f64;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-tumblr .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-tumblr .badge {
  background: #35465c;
  border-color: #35465c;
  color: #fff;
}

.btn-outline-tumblr:hover .badge,
.btn-outline-tumblr:focus .badge,
.btn-outline-tumblr:active .badge,
.btn-outline-tumblr.active .badge,
.show > .btn-outline-tumblr.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #35465c;
}

.text-vimeo {
  color: #1ab7ae !important;
}

a.text-vimeo:hover, a.text-vimeo:focus {
  color: #10746e !important;
}

.bg-vimeo {
  background-color: #1ab7ae !important;
}

a.bg-vimeo:hover, a.bg-vimeo:focus {
  background-color: #19aea5 !important;
}

.bg-vimeo-dark {
  background-color: #17a59d !important;
}

a.bg-vimeo-dark:hover, a.bg-vimeo-dark:focus {
  background-color: #169d95 !important;
}

.bg-vimeo-darker {
  background-color: #169c94 !important;
}

a.bg-vimeo-darker:hover, a.bg-vimeo-darker:focus {
  background-color: #15948d !important;
}

.btn-vimeo {
  background: #1ab7ae;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-vimeo:hover {
  background: #25bbb2;
  color: #fff;
}

.btn-vimeo:hover, .btn-vimeo:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-vimeo .badge {
  background: #fff;
  color: #1ab7ae;
}

.btn-vimeo .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-vimeo:active,
.btn-vimeo.active,
.show > .btn-vimeo.dropdown-toggle {
  background: #38c0b9;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-vimeo.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #1ab7ae;
}

.btn-vimeo.md-btn-flat:focus, .btn-vimeo.md-btn-flat.focus {
  background: rgba(26, 183, 174, 0.1);
}

.btn-vimeo.md-btn-flat .waves-ripple {
  background: rgba(26, 183, 174, 0.3) !important;
}

.btn-vimeo.md-btn-flat:active,
.btn-vimeo.md-btn-flat.active,
.show > .btn-vimeo.md-btn-flat.dropdown-toggle {
  background: rgba(26, 183, 174, 0.15);
}

.btn-vimeo.disabled,
.btn-vimeo:disabled {
  background: #1ab7ae !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-vimeo.md-btn-flat.disabled,
.btn-vimeo.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #1ab7ae !important;
}

.btn-outline-vimeo {
  background: transparent;
  box-shadow: 0 0 0 1px #1ab7ae inset;
  color: #1ab7ae;
}

.btn-outline-vimeo:hover, .btn-outline-vimeo:focus {
  background: #1ab7ae;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-vimeo.disabled, .btn-outline-vimeo:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #1ab7ae inset !important;
  color: #1ab7ae !important;
}

.btn-outline-vimeo:active,
.btn-outline-vimeo.active,
.show > .btn-outline-vimeo.dropdown-toggle {
  background: #25bbb2;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-vimeo .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-vimeo .badge {
  background: #1ab7ae;
  border-color: #1ab7ae;
  color: #fff;
}

.btn-outline-vimeo:hover .badge,
.btn-outline-vimeo:focus .badge,
.btn-outline-vimeo:active .badge,
.btn-outline-vimeo.active .badge,
.show > .btn-outline-vimeo.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #1ab7ae;
}

.text-youtube {
  color: #b31217 !important;
}

a.text-youtube:hover, a.text-youtube:focus {
  color: #6d0b0e !important;
}

.bg-youtube {
  background-color: #b31217 !important;
}

a.bg-youtube:hover, a.bg-youtube:focus {
  background-color: #aa1116 !important;
}

.bg-youtube-dark {
  background-color: #a11015 !important;
}

a.bg-youtube-dark:hover, a.bg-youtube-dark:focus {
  background-color: #990f14 !important;
}

.bg-youtube-darker {
  background-color: #980f14 !important;
}

a.bg-youtube-darker:hover, a.bg-youtube-darker:focus {
  background-color: #900e13 !important;
}

.btn-youtube {
  background: #b31217;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-youtube:hover {
  background: #b71e23;
  color: #fff;
}

.btn-youtube:hover, .btn-youtube:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-youtube .badge {
  background: #fff;
  color: #b31217;
}

.btn-youtube .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-youtube:active,
.btn-youtube.active,
.show > .btn-youtube.dropdown-toggle {
  background: #bd3135;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-youtube.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #b31217;
}

.btn-youtube.md-btn-flat:focus, .btn-youtube.md-btn-flat.focus {
  background: rgba(179, 18, 23, 0.1);
}

.btn-youtube.md-btn-flat .waves-ripple {
  background: rgba(179, 18, 23, 0.3) !important;
}

.btn-youtube.md-btn-flat:active,
.btn-youtube.md-btn-flat.active,
.show > .btn-youtube.md-btn-flat.dropdown-toggle {
  background: rgba(179, 18, 23, 0.15);
}

.btn-youtube.disabled,
.btn-youtube:disabled {
  background: #b31217 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-youtube.md-btn-flat.disabled,
.btn-youtube.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #b31217 !important;
}

.btn-outline-youtube {
  background: transparent;
  box-shadow: 0 0 0 1px #b31217 inset;
  color: #b31217;
}

.btn-outline-youtube:hover, .btn-outline-youtube:focus {
  background: #b31217;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-youtube.disabled, .btn-outline-youtube:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #b31217 inset !important;
  color: #b31217 !important;
}

.btn-outline-youtube:active,
.btn-outline-youtube.active,
.show > .btn-outline-youtube.dropdown-toggle {
  background: #b71e23;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-youtube .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-youtube .badge {
  background: #b31217;
  border-color: #b31217;
  color: #fff;
}

.btn-outline-youtube:hover .badge,
.btn-outline-youtube:focus .badge,
.btn-outline-youtube:active .badge,
.btn-outline-youtube.active .badge,
.show > .btn-outline-youtube.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #b31217;
}

.text-windows {
  color: #26AAE1 !important;
}

a.text-windows:hover, a.text-windows:focus {
  color: #177aa4 !important;
}

.bg-windows {
  background-color: #26AAE1 !important;
}

a.bg-windows:hover, a.bg-windows:focus {
  background-color: #24a2d6 !important;
}

.bg-windows-dark {
  background-color: #2299cb !important;
}

a.bg-windows-dark:hover, a.bg-windows-dark:focus {
  background-color: #2091c1 !important;
}

.bg-windows-darker {
  background-color: #2091bf !important;
}

a.bg-windows-darker:hover, a.bg-windows-darker:focus {
  background-color: #1e8ab5 !important;
}

.btn-windows {
  background: #26AAE1;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-windows:hover {
  background: #31aee3;
  color: #fff;
}

.btn-windows:hover, .btn-windows:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-windows .badge {
  background: #fff;
  color: #26AAE1;
}

.btn-windows .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-windows:active,
.btn-windows.active,
.show > .btn-windows.dropdown-toggle {
  background: #42b5e5;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-windows.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #26AAE1;
}

.btn-windows.md-btn-flat:focus, .btn-windows.md-btn-flat.focus {
  background: rgba(38, 170, 225, 0.1);
}

.btn-windows.md-btn-flat .waves-ripple {
  background: rgba(38, 170, 225, 0.3) !important;
}

.btn-windows.md-btn-flat:active,
.btn-windows.md-btn-flat.active,
.show > .btn-windows.md-btn-flat.dropdown-toggle {
  background: rgba(38, 170, 225, 0.15);
}

.btn-windows.disabled,
.btn-windows:disabled {
  background: #26AAE1 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-windows.md-btn-flat.disabled,
.btn-windows.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #26AAE1 !important;
}

.btn-outline-windows {
  background: transparent;
  box-shadow: 0 0 0 1px #26AAE1 inset;
  color: #26AAE1;
}

.btn-outline-windows:hover, .btn-outline-windows:focus {
  background: #26AAE1;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-windows.disabled, .btn-outline-windows:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #26AAE1 inset !important;
  color: #26AAE1 !important;
}

.btn-outline-windows:active,
.btn-outline-windows.active,
.show > .btn-outline-windows.dropdown-toggle {
  background: #31aee3;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-windows .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-windows .badge {
  background: #26AAE1;
  border-color: #26AAE1;
  color: #fff;
}

.btn-outline-windows:hover .badge,
.btn-outline-windows:focus .badge,
.btn-outline-windows:active .badge,
.btn-outline-windows.active .badge,
.show > .btn-outline-windows.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #26AAE1;
}

.text-macos {
  color: #343434 !important;
}

a.text-macos:hover, a.text-macos:focus {
  color: #0e0e0e !important;
}

.bg-macos {
  background-color: #343434 !important;
}

a.bg-macos:hover, a.bg-macos:focus {
  background-color: #313131 !important;
}

.bg-macos-dark {
  background-color: #2f2f2f !important;
}

a.bg-macos-dark:hover, a.bg-macos-dark:focus {
  background-color: #2d2d2d !important;
}

.bg-macos-darker {
  background-color: #2c2c2c !important;
}

a.bg-macos-darker:hover, a.bg-macos-darker:focus {
  background-color: #2a2a2a !important;
}

.btn-macos {
  background: #343434;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-macos:hover {
  background: #3e3e3e;
  color: #fff;
}

.btn-macos:hover, .btn-macos:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-macos .badge {
  background: #fff;
  color: #343434;
}

.btn-macos .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-macos:active,
.btn-macos.active,
.show > .btn-macos.dropdown-toggle {
  background: #4e4e4e;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-macos.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #343434;
}

.btn-macos.md-btn-flat:focus, .btn-macos.md-btn-flat.focus {
  background: rgba(52, 52, 52, 0.1);
}

.btn-macos.md-btn-flat .waves-ripple {
  background: rgba(52, 52, 52, 0.3) !important;
}

.btn-macos.md-btn-flat:active,
.btn-macos.md-btn-flat.active,
.show > .btn-macos.md-btn-flat.dropdown-toggle {
  background: rgba(52, 52, 52, 0.15);
}

.btn-macos.disabled,
.btn-macos:disabled {
  background: #343434 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-macos.md-btn-flat.disabled,
.btn-macos.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #343434 !important;
}

.btn-outline-macos {
  background: transparent;
  box-shadow: 0 0 0 1px #343434 inset;
  color: #343434;
}

.btn-outline-macos:hover, .btn-outline-macos:focus {
  background: #343434;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-macos.disabled, .btn-outline-macos:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #343434 inset !important;
  color: #343434 !important;
}

.btn-outline-macos:active,
.btn-outline-macos.active,
.show > .btn-outline-macos.dropdown-toggle {
  background: #3e3e3e;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-macos .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-macos .badge {
  background: #343434;
  border-color: #343434;
  color: #fff;
}

.btn-outline-macos:hover .badge,
.btn-outline-macos:focus .badge,
.btn-outline-macos:active .badge,
.btn-outline-macos.active .badge,
.show > .btn-outline-macos.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #343434;
}

.text-linux {
  color: #800080 !important;
}

a.text-linux:hover, a.text-linux:focus {
  color: #340034 !important;
}

.bg-linux {
  background-color: #800080 !important;
}

a.bg-linux:hover, a.bg-linux:focus {
  background-color: #7a007a !important;
}

.bg-linux-dark {
  background-color: #730073 !important;
}

a.bg-linux-dark:hover, a.bg-linux-dark:focus {
  background-color: #6d006d !important;
}

.bg-linux-darker {
  background-color: #6d006d !important;
}

a.bg-linux-darker:hover, a.bg-linux-darker:focus {
  background-color: #680068 !important;
}

.btn-linux {
  background: #800080;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #fff;
}

.btn-linux:hover {
  background: #860d86;
  color: #fff;
}

.btn-linux:hover, .btn-linux:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-linux .badge {
  background: #fff;
  color: #800080;
}

.btn-linux .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-linux:active,
.btn-linux.active,
.show > .btn-linux.dropdown-toggle {
  background: #912191;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-linux.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #800080;
}

.btn-linux.md-btn-flat:focus, .btn-linux.md-btn-flat.focus {
  background: rgba(128, 0, 128, 0.1);
}

.btn-linux.md-btn-flat .waves-ripple {
  background: rgba(128, 0, 128, 0.3) !important;
}

.btn-linux.md-btn-flat:active,
.btn-linux.md-btn-flat.active,
.show > .btn-linux.md-btn-flat.dropdown-toggle {
  background: rgba(128, 0, 128, 0.15);
}

.btn-linux.disabled,
.btn-linux:disabled {
  background: #800080 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
}

.btn-linux.md-btn-flat.disabled,
.btn-linux.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #800080 !important;
}

.btn-outline-linux {
  background: transparent;
  box-shadow: 0 0 0 1px #800080 inset;
  color: #800080;
}

.btn-outline-linux:hover, .btn-outline-linux:focus {
  background: #800080;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-linux.disabled, .btn-outline-linux:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #800080 inset !important;
  color: #800080 !important;
}

.btn-outline-linux:active,
.btn-outline-linux.active,
.show > .btn-outline-linux.dropdown-toggle {
  background: #860d86;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #fff;
}

.btn-outline-linux .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-outline-linux .badge {
  background: #800080;
  border-color: #800080;
  color: #fff;
}

.btn-outline-linux:hover .badge,
.btn-outline-linux:focus .badge,
.btn-outline-linux:active .badge,
.btn-outline-linux.active .badge,
.show > .btn-outline-linux.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #800080;
}

.btn-white {
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  color: #4E5155;
}

.btn-white:hover {
  background: white;
  color: #4E5155;
}

.btn-white:hover, .btn-white:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
}

.btn-white .badge {
  background: #4E5155;
  color: #fff;
}

.btn-white .waves-ripple {
  background: rgba(78, 81, 85, 0.2) !important;
}

.btn-white:active,
.btn-white.active,
.show > .btn-white.dropdown-toggle {
  background: white;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
}

.btn-white.md-btn-flat {
  background: none;
  box-shadow: none;
  color: #fff;
}

.btn-white.md-btn-flat:focus, .btn-white.md-btn-flat.focus {
  background: rgba(255, 255, 255, 0.1);
}

.btn-white.md-btn-flat .waves-ripple {
  background: rgba(255, 255, 255, 0.3) !important;
}

.btn-white.md-btn-flat:active,
.btn-white.md-btn-flat.active,
.show > .btn-white.md-btn-flat.dropdown-toggle {
  background: rgba(255, 255, 255, 0.15);
}

.btn-white.disabled,
.btn-white:disabled {
  background: #fff !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03) !important;
  color: #4E5155 !important;
}

.btn-white.md-btn-flat.disabled,
.btn-white.md-btn-flat:disabled {
  background: none !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-outline-white {
  background: transparent;
  box-shadow: 0 0 0 1px #fff inset;
  color: #fff;
}

.btn-outline-white:hover, .btn-outline-white:focus {
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  color: #4E5155;
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  background: transparent !important;
  box-shadow: 0 0 0 1px #fff inset !important;
  color: #fff !important;
}

.btn-outline-white:active,
.btn-outline-white.active,
.show > .btn-outline-white.dropdown-toggle {
  background: white;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.15), 0px 8px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06);
  color: #4E5155;
}

.btn-outline-white .waves-ripple {
  background: rgba(78, 81, 85, 0.2) !important;
}

.btn-outline-white .badge {
  background: #fff;
  border-color: #fff;
  color: #fff;
}

.btn-outline-white:hover .badge,
.btn-outline-white:focus .badge,
.btn-outline-white:active .badge,
.btn-outline-white.active .badge,
.show > .btn-outline-white.dropdown-toggle .badge {
  background: #4E5155;
  border-color: #4E5155;
  color: #fff;
}

.custom-control.custom-control-black .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-black .custom-control-input:active ~ .custom-control-label::before {
  background-color: #000;
}

.custom-control.custom-control-black.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-black.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-black.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #000;
}

.custom-control.custom-control-black.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #000;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-black.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.25 4.35 4.18 6.75 9.25 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-black.custom-radio .custom-control-input ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23000'/%3E%3C/svg%3E");
}

.custom-control.custom-control-black .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(0, 0, 0, 0.15);
}

.custom-control.custom-control-white .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-white .custom-control-input:active ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-control.custom-control-white.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-white.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-white.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #fff;
}

.custom-control.custom-control-white.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23666666' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-white.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.25 4.35 4.18 6.75 9.25 0.75' style='fill:none;stroke:%23666666;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-white.custom-radio .custom-control-input ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-control.custom-control-white .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(255, 255, 255, 0.15);
}

.custom-control.custom-control-silver .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-silver .custom-control-input:active ~ .custom-control-label::before {
  background-color: #eee;
}

.custom-control.custom-control-silver.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-silver.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-silver.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #eee;
}

.custom-control.custom-control-silver.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #eee;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%235f5f5f' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-silver.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #eee;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.25 4.35 4.18 6.75 9.25 0.75' style='fill:none;stroke:%235f5f5f;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-silver.custom-radio .custom-control-input ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23eee'/%3E%3C/svg%3E");
}

.custom-control.custom-control-silver .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(238, 238, 238, 0.15);
}

.custom-control.custom-control-gray .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-gray .custom-control-input:active ~ .custom-control-label::before {
  background-color: #777;
}

.custom-control.custom-control-gray.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-gray.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-gray.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #777;
}

.custom-control.custom-control-gray.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #777;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gray.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #777;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.25 4.35 4.18 6.75 9.25 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gray.custom-radio .custom-control-input ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23777'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gray .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(119, 119, 119, 0.15);
}

.custom-control.custom-control-gold .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-gold .custom-control-input:active ~ .custom-control-label::before {
  background-color: #ffeb3b;
}

.custom-control.custom-control-gold.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-gold.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-gold.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ffeb3b;
}

.custom-control.custom-control-gold.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #ffeb3b;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23665e18' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gold.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffeb3b;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.25 4.35 4.18 6.75 9.25 0.75' style='fill:none;stroke:%23665e18;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gold.custom-radio .custom-control-input ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23ffeb3b'/%3E%3C/svg%3E");
}

.custom-control.custom-control-gold .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(255, 235, 59, 0.15);
}

.custom-control.custom-control-pink .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-pink .custom-control-input:active ~ .custom-control-label::before {
  background-color: #e91e63;
}

.custom-control.custom-control-pink.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-pink.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-pink.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #e91e63;
}

.custom-control.custom-control-pink.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #e91e63;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-pink.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e91e63;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.25 4.35 4.18 6.75 9.25 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-pink.custom-radio .custom-control-input ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23e91e63'/%3E%3C/svg%3E");
}

.custom-control.custom-control-pink .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(233, 30, 99, 0.15);
}

.custom-control.custom-control-red .custom-control-input ~ .custom-control-label::before,
.custom-control.custom-control-red .custom-control-input:active ~ .custom-control-label::before {
  background-color: #f44336;
}

.custom-control.custom-control-red.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-control-red.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-control-red.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #f44336;
}

.custom-control.custom-control-red.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #f44336;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-control-red.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f44336;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.25 4.35 4.18 6.75 9.25 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-control-red.custom-radio .custom-control-input ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23f44336'/%3E%3C/svg%3E");
}

.custom-control.custom-control-red .custom-control-input:checked ~ .custom-control-label::after {
  background: rgba(244, 67, 54, 0.15);
}

.navbar.bg-secondary {
  background-color: #8897AA !important;
  color: #ebeef1;
}

.navbar.bg-secondary .navbar-brand {
  color: #fff;
}

.navbar.bg-secondary .navbar-brand:hover, .navbar.bg-secondary .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-secondary .navbar-nav .nav-link {
  color: #ebeef1;
}

.navbar.bg-secondary .navbar-nav .nav-link:hover, .navbar.bg-secondary .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-secondary .navbar-nav .nav-link.disabled {
  color: #c3cbd5 !important;
}

.navbar.bg-secondary .navbar-nav .show > .nav-link,
.navbar.bg-secondary .navbar-nav .active > .nav-link,
.navbar.bg-secondary .navbar-nav .nav-link.show,
.navbar.bg-secondary .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-secondary .navbar-toggler {
  color: #ebeef1;
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-secondary .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar.bg-secondary .navbar-text {
  color: #ebeef1;
}

.navbar.bg-secondary .navbar-text a {
  color: #fff;
}

.navbar.bg-secondary .navbar-text a:hover, .navbar.bg-secondary .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-secondary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-success {
  background-color: #02BC77 !important;
  color: #cbf1e3;
}

.navbar.bg-success .navbar-brand {
  color: #fff;
}

.navbar.bg-success .navbar-brand:hover, .navbar.bg-success .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-success .navbar-nav .nav-link {
  color: #cbf1e3;
}

.navbar.bg-success .navbar-nav .nav-link:hover, .navbar.bg-success .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-success .navbar-nav .nav-link.disabled {
  color: #7bdcb8 !important;
}

.navbar.bg-success .navbar-nav .show > .nav-link,
.navbar.bg-success .navbar-nav .active > .nav-link,
.navbar.bg-success .navbar-nav .nav-link.show,
.navbar.bg-success .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-success .navbar-toggler {
  color: #cbf1e3;
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-success .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar.bg-success .navbar-text {
  color: #cbf1e3;
}

.navbar.bg-success .navbar-text a {
  color: #fff;
}

.navbar.bg-success .navbar-text a:hover, .navbar.bg-success .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-success hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-info {
  background-color: #28c3d7 !important;
  color: #dcf5f8;
}

.navbar.bg-info .navbar-brand {
  color: #fff;
}

.navbar.bg-info .navbar-brand:hover, .navbar.bg-info .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-info .navbar-nav .nav-link {
  color: #dcf5f8;
}

.navbar.bg-info .navbar-nav .nav-link:hover, .navbar.bg-info .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-info .navbar-nav .nav-link.disabled {
  color: #94e1eb !important;
}

.navbar.bg-info .navbar-nav .show > .nav-link,
.navbar.bg-info .navbar-nav .active > .nav-link,
.navbar.bg-info .navbar-nav .nav-link.show,
.navbar.bg-info .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-info .navbar-toggler {
  color: #dcf5f8;
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-info .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar.bg-info .navbar-text {
  color: #dcf5f8;
}

.navbar.bg-info .navbar-text a {
  color: #fff;
}

.navbar.bg-info .navbar-text a:hover, .navbar.bg-info .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-info hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-warning {
  background-color: #FFD950 !important;
  color: #998230;
}

.navbar.bg-warning .navbar-brand {
  color: #665720;
}

.navbar.bg-warning .navbar-brand:hover, .navbar.bg-warning .navbar-brand:focus {
  color: #665720;
}

.navbar.bg-warning .navbar-nav .nav-link {
  color: #998230;
}

.navbar.bg-warning .navbar-nav .nav-link:hover, .navbar.bg-warning .navbar-nav .nav-link:focus {
  color: #665720;
}

.navbar.bg-warning .navbar-nav .nav-link.disabled {
  color: #c2a53d !important;
}

.navbar.bg-warning .navbar-nav .show > .nav-link,
.navbar.bg-warning .navbar-nav .active > .nav-link,
.navbar.bg-warning .navbar-nav .nav-link.show,
.navbar.bg-warning .navbar-nav .nav-link.active {
  color: #665720;
}

.navbar.bg-warning .navbar-toggler {
  color: #998230;
  border-color: rgba(102, 87, 32, 0.0832855);
}

.navbar.bg-warning .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar.bg-warning .navbar-text {
  color: #998230;
}

.navbar.bg-warning .navbar-text a {
  color: #665720;
}

.navbar.bg-warning .navbar-text a:hover, .navbar.bg-warning .navbar-text a:focus {
  color: #665720;
}

.navbar.bg-warning hr {
  border-color: rgba(102, 87, 32, 0.0832855);
}

.navbar.bg-danger {
  background-color: #d9534f !important;
  color: #f7dbda;
}

.navbar.bg-danger .navbar-brand {
  color: #fff;
}

.navbar.bg-danger .navbar-brand:hover, .navbar.bg-danger .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-danger .navbar-nav .nav-link {
  color: #f7dbda;
}

.navbar.bg-danger .navbar-nav .nav-link:hover, .navbar.bg-danger .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-danger .navbar-nav .nav-link.disabled {
  color: #eba5a2 !important;
}

.navbar.bg-danger .navbar-nav .show > .nav-link,
.navbar.bg-danger .navbar-nav .active > .nav-link,
.navbar.bg-danger .navbar-nav .nav-link.show,
.navbar.bg-danger .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-danger .navbar-toggler {
  color: #f7dbda;
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-danger .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar.bg-danger .navbar-text {
  color: #f7dbda;
}

.navbar.bg-danger .navbar-text a {
  color: #fff;
}

.navbar.bg-danger .navbar-text a:hover, .navbar.bg-danger .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-danger hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-dark {
  background-color: #2f3337 !important;
  color: #9d9fa1;
}

.navbar.bg-dark .navbar-brand {
  color: #fff;
}

.navbar.bg-dark .navbar-brand:hover, .navbar.bg-dark .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-dark .navbar-nav .nav-link {
  color: #9d9fa1;
}

.navbar.bg-dark .navbar-nav .nav-link:hover, .navbar.bg-dark .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-dark .navbar-nav .nav-link.disabled {
  color: #717477 !important;
}

.navbar.bg-dark .navbar-nav .show > .nav-link,
.navbar.bg-dark .navbar-nav .active > .nav-link,
.navbar.bg-dark .navbar-nav .nav-link.show,
.navbar.bg-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-dark .navbar-toggler {
  color: #9d9fa1;
  border-color: rgba(255, 255, 255, 0.06);
}

.navbar.bg-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar.bg-dark .navbar-text {
  color: #9d9fa1;
}

.navbar.bg-dark .navbar-text a {
  color: #fff;
}

.navbar.bg-dark .navbar-text a:hover, .navbar.bg-dark .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-dark hr {
  border-color: rgba(255, 255, 255, 0.06);
}

.navbar.bg-white {
  background-color: #fff !important;
  color: #4E5155;
}

.navbar.bg-white .navbar-brand {
  color: #666666;
}

.navbar.bg-white .navbar-brand:hover, .navbar.bg-white .navbar-brand:focus {
  color: #666666;
}

.navbar.bg-white .navbar-nav .nav-link {
  color: #4E5155;
}

.navbar.bg-white .navbar-nav .nav-link:hover, .navbar.bg-white .navbar-nav .nav-link:focus {
  color: #666666;
}

.navbar.bg-white .navbar-nav .nav-link.disabled {
  color: #959799 !important;
}

.navbar.bg-white .navbar-nav .show > .nav-link,
.navbar.bg-white .navbar-nav .active > .nav-link,
.navbar.bg-white .navbar-nav .nav-link.show,
.navbar.bg-white .navbar-nav .nav-link.active {
  color: #666666;
}

.navbar.bg-white .navbar-toggler {
  color: #4E5155;
  border-color: rgba(102, 102, 102, 0.075);
}

.navbar.bg-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar.bg-white .navbar-text {
  color: #4E5155;
}

.navbar.bg-white .navbar-text a {
  color: #666666;
}

.navbar.bg-white .navbar-text a:hover, .navbar.bg-white .navbar-text a:focus {
  color: #666666;
}

.navbar.bg-white hr {
  border-color: rgba(102, 102, 102, 0.075);
}

.navbar.bg-light {
  background-color: #f1f1f2 !important;
  color: #4E5155;
}

.navbar.bg-light .navbar-brand {
  color: #606061;
}

.navbar.bg-light .navbar-brand:hover, .navbar.bg-light .navbar-brand:focus {
  color: #606061;
}

.navbar.bg-light .navbar-nav .nav-link {
  color: #4E5155;
}

.navbar.bg-light .navbar-nav .nav-link:hover, .navbar.bg-light .navbar-nav .nav-link:focus {
  color: #606061;
}

.navbar.bg-light .navbar-nav .nav-link.disabled {
  color: #8f9194 !important;
}

.navbar.bg-light .navbar-nav .show > .nav-link,
.navbar.bg-light .navbar-nav .active > .nav-link,
.navbar.bg-light .navbar-nav .nav-link.show,
.navbar.bg-light .navbar-nav .nav-link.active {
  color: #606061;
}

.navbar.bg-light .navbar-toggler {
  color: #4E5155;
  border-color: rgba(96, 96, 97, 0.0777227);
}

.navbar.bg-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar.bg-light .navbar-text {
  color: #4E5155;
}

.navbar.bg-light .navbar-text a {
  color: #606061;
}

.navbar.bg-light .navbar-text a:hover, .navbar.bg-light .navbar-text a:focus {
  color: #606061;
}

.navbar.bg-light hr {
  border-color: rgba(96, 96, 97, 0.0777227);
}

.navbar.bg-lighter {
  background-color: #f8f8f8 !important;
  color: #4E5155;
}

.navbar.bg-lighter .navbar-brand {
  color: #636363;
}

.navbar.bg-lighter .navbar-brand:hover, .navbar.bg-lighter .navbar-brand:focus {
  color: #636363;
}

.navbar.bg-lighter .navbar-nav .nav-link {
  color: #4E5155;
}

.navbar.bg-lighter .navbar-nav .nav-link:hover, .navbar.bg-lighter .navbar-nav .nav-link:focus {
  color: #636363;
}

.navbar.bg-lighter .navbar-nav .nav-link.disabled {
  color: #929496 !important;
}

.navbar.bg-lighter .navbar-nav .show > .nav-link,
.navbar.bg-lighter .navbar-nav .active > .nav-link,
.navbar.bg-lighter .navbar-nav .nav-link.show,
.navbar.bg-lighter .navbar-nav .nav-link.active {
  color: #636363;
}

.navbar.bg-lighter .navbar-toggler {
  color: #4E5155;
  border-color: rgba(99, 99, 99, 0.0763725);
}

.navbar.bg-lighter .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar.bg-lighter .navbar-text {
  color: #4E5155;
}

.navbar.bg-lighter .navbar-text a {
  color: #636363;
}

.navbar.bg-lighter .navbar-text a:hover, .navbar.bg-lighter .navbar-text a:focus {
  color: #636363;
}

.navbar.bg-lighter hr {
  border-color: rgba(99, 99, 99, 0.0763725);
}

.sidenav.bg-secondary {
  background-color: #8897AA !important;
  color: #ebeef1;
}

.sidenav.bg-secondary .sidenav-link,
.sidenav.bg-secondary .sidenav-horizontal-prev,
.sidenav.bg-secondary .sidenav-horizontal-next {
  color: #ebeef1;
}

.sidenav.bg-secondary .sidenav-link:hover, .sidenav.bg-secondary .sidenav-link:focus,
.sidenav.bg-secondary .sidenav-horizontal-prev:hover,
.sidenav.bg-secondary .sidenav-horizontal-prev:focus,
.sidenav.bg-secondary .sidenav-horizontal-next:hover,
.sidenav.bg-secondary .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-secondary .sidenav-link.active,
.sidenav.bg-secondary .sidenav-horizontal-prev.active,
.sidenav.bg-secondary .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-secondary .sidenav-item.disabled .sidenav-link,
.sidenav.bg-secondary .sidenav-horizontal-prev.disabled,
.sidenav.bg-secondary .sidenav-horizontal-next.disabled {
  color: #c3cbd5 !important;
}

.sidenav.bg-secondary .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-secondary .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-secondary .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #818fa2;
}

.sidenav.bg-secondary.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #7b889a;
}

.sidenav.bg-secondary.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-secondary.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #818fa2;
}

.sidenav.bg-secondary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-secondary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #ebeef1;
}

.sidenav.bg-secondary .sidenav-text {
  color: #fff;
}

.sidenav.bg-secondary .sidenav-header {
  color: #d2d8df;
}

.sidenav.bg-secondary hr,
.sidenav.bg-secondary .sidenav-divider,
.sidenav.bg-secondary .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.sidenav.bg-secondary .sidenav-inner > .sidenav-header::before,
.sidenav.bg-secondary .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #94a0b0;
}

.sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-secondary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #818fa2;
}

.sidenav.bg-secondary .ps__thumb-y,
.sidenav.bg-secondary .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.633225) !important;
}

.sidenav.bg-success {
  background-color: #02BC77 !important;
  color: #cbf1e3;
}

.sidenav.bg-success .sidenav-link,
.sidenav.bg-success .sidenav-horizontal-prev,
.sidenav.bg-success .sidenav-horizontal-next {
  color: #cbf1e3;
}

.sidenav.bg-success .sidenav-link:hover, .sidenav.bg-success .sidenav-link:focus,
.sidenav.bg-success .sidenav-horizontal-prev:hover,
.sidenav.bg-success .sidenav-horizontal-prev:focus,
.sidenav.bg-success .sidenav-horizontal-next:hover,
.sidenav.bg-success .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-success .sidenav-link.active,
.sidenav.bg-success .sidenav-horizontal-prev.active,
.sidenav.bg-success .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-success .sidenav-item.disabled .sidenav-link,
.sidenav.bg-success .sidenav-horizontal-prev.disabled,
.sidenav.bg-success .sidenav-horizontal-next.disabled {
  color: #7bdcb8 !important;
}

.sidenav.bg-success .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-success .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-success .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #02b371;
}

.sidenav.bg-success.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #02aa6b;
}

.sidenav.bg-success.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-success.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #02b371;
}

.sidenav.bg-success .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-success .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #cbf1e3;
}

.sidenav.bg-success .sidenav-text {
  color: #fff;
}

.sidenav.bg-success .sidenav-header {
  color: #99e4c8;
}

.sidenav.bg-success hr,
.sidenav.bg-success .sidenav-divider,
.sidenav.bg-success .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.sidenav.bg-success .sidenav-inner > .sidenav-header::before,
.sidenav.bg-success .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #28be86;
}

.sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-success .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #02b371;
}

.sidenav.bg-success .ps__thumb-y,
.sidenav.bg-success .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.595325) !important;
}

.sidenav.bg-info {
  background-color: #28c3d7 !important;
  color: #dcf5f8;
}

.sidenav.bg-info .sidenav-link,
.sidenav.bg-info .sidenav-horizontal-prev,
.sidenav.bg-info .sidenav-horizontal-next {
  color: #dcf5f8;
}

.sidenav.bg-info .sidenav-link:hover, .sidenav.bg-info .sidenav-link:focus,
.sidenav.bg-info .sidenav-horizontal-prev:hover,
.sidenav.bg-info .sidenav-horizontal-prev:focus,
.sidenav.bg-info .sidenav-horizontal-next:hover,
.sidenav.bg-info .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-info .sidenav-link.active,
.sidenav.bg-info .sidenav-horizontal-prev.active,
.sidenav.bg-info .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-info .sidenav-item.disabled .sidenav-link,
.sidenav.bg-info .sidenav-horizontal-prev.disabled,
.sidenav.bg-info .sidenav-horizontal-next.disabled {
  color: #94e1eb !important;
}

.sidenav.bg-info .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-info .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-info .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #26b9cc;
}

.sidenav.bg-info.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #24b0c2;
}

.sidenav.bg-info.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-info.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #26b9cc;
}

.sidenav.bg-info .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-info .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #dcf5f8;
}

.sidenav.bg-info .sidenav-text {
  color: #fff;
}

.sidenav.bg-info .sidenav-header {
  color: #afe9f0;
}

.sidenav.bg-info hr,
.sidenav.bg-info .sidenav-divider,
.sidenav.bg-info .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.sidenav.bg-info .sidenav-inner > .sidenav-header::before,
.sidenav.bg-info .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #47c4d4;
}

.sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-info .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #26b9cc;
}

.sidenav.bg-info .ps__thumb-y,
.sidenav.bg-info .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.636761) !important;
}

.sidenav.bg-warning {
  background-color: #FFD950 !important;
  color: #998230;
}

.sidenav.bg-warning .sidenav-link,
.sidenav.bg-warning .sidenav-horizontal-prev,
.sidenav.bg-warning .sidenav-horizontal-next {
  color: #998230;
}

.sidenav.bg-warning .sidenav-link:hover, .sidenav.bg-warning .sidenav-link:focus,
.sidenav.bg-warning .sidenav-horizontal-prev:hover,
.sidenav.bg-warning .sidenav-horizontal-prev:focus,
.sidenav.bg-warning .sidenav-horizontal-next:hover,
.sidenav.bg-warning .sidenav-horizontal-next:focus {
  color: #665720;
}

.sidenav.bg-warning .sidenav-link.active,
.sidenav.bg-warning .sidenav-horizontal-prev.active,
.sidenav.bg-warning .sidenav-horizontal-next.active {
  color: #665720;
}

.sidenav.bg-warning .sidenav-item.disabled .sidenav-link,
.sidenav.bg-warning .sidenav-horizontal-prev.disabled,
.sidenav.bg-warning .sidenav-horizontal-next.disabled {
  color: #c2a53d !important;
}

.sidenav.bg-warning .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-warning .sidenav-item.active > .sidenav-link {
  color: #665720;
}

.sidenav.bg-warning .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #f9d44e;
}

.sidenav.bg-warning.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #f3cf4c;
}

.sidenav.bg-warning.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-warning.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #f9d44e;
}

.sidenav.bg-warning .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-warning .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #998230;
}

.sidenav.bg-warning .sidenav-text {
  color: #665720;
}

.sidenav.bg-warning .sidenav-header {
  color: #b39838;
}

.sidenav.bg-warning hr,
.sidenav.bg-warning .sidenav-divider,
.sidenav.bg-warning .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(102, 87, 32, 0.0832855) !important;
}

.sidenav.bg-warning .sidenav-inner > .sidenav-header::before,
.sidenav.bg-warning .sidenav-block::before {
  background-color: rgba(102, 87, 32, 0.0832855);
}

.sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #edca4a;
}

.sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #665720;
}

.sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-warning .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #f9d44e;
}

.sidenav.bg-warning .ps__thumb-y,
.sidenav.bg-warning .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(102, 87, 32, 0.266284) !important;
}

.sidenav.bg-danger {
  background-color: #d9534f !important;
  color: #f7dbda;
}

.sidenav.bg-danger .sidenav-link,
.sidenav.bg-danger .sidenav-horizontal-prev,
.sidenav.bg-danger .sidenav-horizontal-next {
  color: #f7dbda;
}

.sidenav.bg-danger .sidenav-link:hover, .sidenav.bg-danger .sidenav-link:focus,
.sidenav.bg-danger .sidenav-horizontal-prev:hover,
.sidenav.bg-danger .sidenav-horizontal-prev:focus,
.sidenav.bg-danger .sidenav-horizontal-next:hover,
.sidenav.bg-danger .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-danger .sidenav-link.active,
.sidenav.bg-danger .sidenav-horizontal-prev.active,
.sidenav.bg-danger .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-danger .sidenav-item.disabled .sidenav-link,
.sidenav.bg-danger .sidenav-horizontal-prev.disabled,
.sidenav.bg-danger .sidenav-horizontal-next.disabled {
  color: #eba5a2 !important;
}

.sidenav.bg-danger .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-danger .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-danger .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #ce4f4b;
}

.sidenav.bg-danger.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #c44b47;
}

.sidenav.bg-danger.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-danger.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #ce4f4b;
}

.sidenav.bg-danger .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-danger .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #f7dbda;
}

.sidenav.bg-danger .sidenav-text {
  color: #fff;
}

.sidenav.bg-danger .sidenav-header {
  color: #f0b9b7;
}

.sidenav.bg-danger hr,
.sidenav.bg-danger .sidenav-divider,
.sidenav.bg-danger .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.sidenav.bg-danger .sidenav-inner > .sidenav-header::before,
.sidenav.bg-danger .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #d56966;
}

.sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-danger .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #ce4f4b;
}

.sidenav.bg-danger .ps__thumb-y,
.sidenav.bg-danger .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.592329) !important;
}

.sidenav.bg-dark {
  background-color: #2f3337 !important;
  color: #9d9fa1;
}

.sidenav.bg-dark .sidenav-link,
.sidenav.bg-dark .sidenav-horizontal-prev,
.sidenav.bg-dark .sidenav-horizontal-next {
  color: #9d9fa1;
}

.sidenav.bg-dark .sidenav-link:hover, .sidenav.bg-dark .sidenav-link:focus,
.sidenav.bg-dark .sidenav-horizontal-prev:hover,
.sidenav.bg-dark .sidenav-horizontal-prev:focus,
.sidenav.bg-dark .sidenav-horizontal-next:hover,
.sidenav.bg-dark .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-dark .sidenav-link.active,
.sidenav.bg-dark .sidenav-horizontal-prev.active,
.sidenav.bg-dark .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-dark .sidenav-item.disabled .sidenav-link,
.sidenav.bg-dark .sidenav-horizontal-prev.disabled,
.sidenav.bg-dark .sidenav-horizontal-next.disabled {
  color: #717477 !important;
}

.sidenav.bg-dark .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-dark .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-dark .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #393d41;
}

.sidenav.bg-dark.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #43474b;
}

.sidenav.bg-dark.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-dark.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #393d41;
}

.sidenav.bg-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-dark .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #9d9fa1;
}

.sidenav.bg-dark .sidenav-text {
  color: #fff;
}

.sidenav.bg-dark .sidenav-header {
  color: #828487;
}

.sidenav.bg-dark hr,
.sidenav.bg-dark .sidenav-divider,
.sidenav.bg-dark .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.06) !important;
}

.sidenav.bg-dark .sidenav-inner > .sidenav-header::before,
.sidenav.bg-dark .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.06);
}

.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #45494c;
}

.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-dark .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #393d41;
}

.sidenav.bg-dark .ps__thumb-y,
.sidenav.bg-dark .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.478839) !important;
}

.sidenav.bg-white {
  background-color: #fff !important;
  color: #a3a4a6;
}

.sidenav.bg-white .sidenav-link,
.sidenav.bg-white .sidenav-horizontal-prev,
.sidenav.bg-white .sidenav-horizontal-next {
  color: #a3a4a6;
}

.sidenav.bg-white .sidenav-link:hover, .sidenav.bg-white .sidenav-link:focus,
.sidenav.bg-white .sidenav-horizontal-prev:hover,
.sidenav.bg-white .sidenav-horizontal-prev:focus,
.sidenav.bg-white .sidenav-horizontal-next:hover,
.sidenav.bg-white .sidenav-horizontal-next:focus {
  color: #4E5155;
}

.sidenav.bg-white .sidenav-link.active,
.sidenav.bg-white .sidenav-horizontal-prev.active,
.sidenav.bg-white .sidenav-horizontal-next.active {
  color: #4E5155;
}

.sidenav.bg-white .sidenav-item.disabled .sidenav-link,
.sidenav.bg-white .sidenav-horizontal-prev.disabled,
.sidenav.bg-white .sidenav-horizontal-next.disabled {
  color: #c8c8ca !important;
}

.sidenav.bg-white .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-white .sidenav-item.active > .sidenav-link {
  color: #4E5155;
}

.sidenav.bg-white .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #fff;
}

.sidenav.bg-white.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #f9f9f9;
}

.sidenav.bg-white.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-white.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #fff;
}

.sidenav.bg-white .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-white .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #a3a4a6;
}

.sidenav.bg-white .sidenav-text {
  color: #4E5155;
}

.sidenav.bg-white .sidenav-header {
  color: #babbbc;
}

.sidenav.bg-white hr,
.sidenav.bg-white .sidenav-divider,
.sidenav.bg-white .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(78, 81, 85, 0.075) !important;
}

.sidenav.bg-white .sidenav-inner > .sidenav-header::before,
.sidenav.bg-white .sidenav-block::before {
  background-color: rgba(78, 81, 85, 0.075);
}

.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #f2f2f2;
}

.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #4E5155;
}

.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-white .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #fff;
}

.sidenav.bg-white .ps__thumb-y,
.sidenav.bg-white .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(78, 81, 85, 0.2) !important;
}

.sidenav.bg-light {
  background-color: #f1f1f2 !important;
  color: #a3a4a6;
}

.sidenav.bg-light .sidenav-link,
.sidenav.bg-light .sidenav-horizontal-prev,
.sidenav.bg-light .sidenav-horizontal-next {
  color: #a3a4a6;
}

.sidenav.bg-light .sidenav-link:hover, .sidenav.bg-light .sidenav-link:focus,
.sidenav.bg-light .sidenav-horizontal-prev:hover,
.sidenav.bg-light .sidenav-horizontal-prev:focus,
.sidenav.bg-light .sidenav-horizontal-next:hover,
.sidenav.bg-light .sidenav-horizontal-next:focus {
  color: #4E5155;
}

.sidenav.bg-light .sidenav-link.active,
.sidenav.bg-light .sidenav-horizontal-prev.active,
.sidenav.bg-light .sidenav-horizontal-next.active {
  color: #4E5155;
}

.sidenav.bg-light .sidenav-item.disabled .sidenav-link,
.sidenav.bg-light .sidenav-horizontal-prev.disabled,
.sidenav.bg-light .sidenav-horizontal-next.disabled {
  color: #c2c3c4 !important;
}

.sidenav.bg-light .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-light .sidenav-item.active > .sidenav-link {
  color: #4E5155;
}

.sidenav.bg-light .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #ebebec;
}

.sidenav.bg-light.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #e5e5e6;
}

.sidenav.bg-light.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-light.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #ebebec;
}

.sidenav.bg-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-light .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #a3a4a6;
}

.sidenav.bg-light .sidenav-text {
  color: #4E5155;
}

.sidenav.bg-light .sidenav-header {
  color: #b7b7b9;
}

.sidenav.bg-light hr,
.sidenav.bg-light .sidenav-divider,
.sidenav.bg-light .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(78, 81, 85, 0.0777227) !important;
}

.sidenav.bg-light .sidenav-inner > .sidenav-header::before,
.sidenav.bg-light .sidenav-block::before {
  background-color: rgba(78, 81, 85, 0.0777227);
}

.sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #dfdfe0;
}

.sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #4E5155;
}

.sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-light .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #ebebec;
}

.sidenav.bg-light .ps__thumb-y,
.sidenav.bg-light .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(78, 81, 85, 0.221782) !important;
}

.sidenav.bg-lighter {
  background-color: #f8f8f8 !important;
  color: #a3a4a6;
}

.sidenav.bg-lighter .sidenav-link,
.sidenav.bg-lighter .sidenav-horizontal-prev,
.sidenav.bg-lighter .sidenav-horizontal-next {
  color: #a3a4a6;
}

.sidenav.bg-lighter .sidenav-link:hover, .sidenav.bg-lighter .sidenav-link:focus,
.sidenav.bg-lighter .sidenav-horizontal-prev:hover,
.sidenav.bg-lighter .sidenav-horizontal-prev:focus,
.sidenav.bg-lighter .sidenav-horizontal-next:hover,
.sidenav.bg-lighter .sidenav-horizontal-next:focus {
  color: #4E5155;
}

.sidenav.bg-lighter .sidenav-link.active,
.sidenav.bg-lighter .sidenav-horizontal-prev.active,
.sidenav.bg-lighter .sidenav-horizontal-next.active {
  color: #4E5155;
}

.sidenav.bg-lighter .sidenav-item.disabled .sidenav-link,
.sidenav.bg-lighter .sidenav-horizontal-prev.disabled,
.sidenav.bg-lighter .sidenav-horizontal-next.disabled {
  color: #c5c6c7 !important;
}

.sidenav.bg-lighter .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-lighter .sidenav-item.active > .sidenav-link {
  color: #4E5155;
}

.sidenav.bg-lighter .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #f2f2f2;
}

.sidenav.bg-lighter.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #ececec;
}

.sidenav.bg-lighter.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-lighter.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #f2f2f2;
}

.sidenav.bg-lighter .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-lighter .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #a3a4a6;
}

.sidenav.bg-lighter .sidenav-text {
  color: #4E5155;
}

.sidenav.bg-lighter .sidenav-header {
  color: #b8b9bb;
}

.sidenav.bg-lighter hr,
.sidenav.bg-lighter .sidenav-divider,
.sidenav.bg-lighter .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(78, 81, 85, 0.0763725) !important;
}

.sidenav.bg-lighter .sidenav-inner > .sidenav-header::before,
.sidenav.bg-lighter .sidenav-block::before {
  background-color: rgba(78, 81, 85, 0.0763725);
}

.sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #e5e6e6;
}

.sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #4E5155;
}

.sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-lighter .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #f2f2f2;
}

.sidenav.bg-lighter .ps__thumb-y,
.sidenav.bg-lighter .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(78, 81, 85, 0.21098) !important;
}

.footer.bg-secondary {
  background-color: #8897AA !important;
  color: #ebeef1;
}

.footer.bg-secondary .footer-link {
  color: #ebeef1;
}

.footer.bg-secondary .footer-link:hover, .footer.bg-secondary .footer-link:focus {
  color: #fff;
}

.footer.bg-secondary .footer-link.disabled {
  color: #c3cbd5 !important;
}

.footer.bg-secondary .footer-text {
  color: #fff;
}

.footer.bg-secondary .show > .footer-link,
.footer.bg-secondary .active > .footer-link,
.footer.bg-secondary .footer-link.show,
.footer.bg-secondary .footer-link.active {
  color: #fff;
}

.footer.bg-secondary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-success {
  background-color: #02BC77 !important;
  color: #cbf1e3;
}

.footer.bg-success .footer-link {
  color: #cbf1e3;
}

.footer.bg-success .footer-link:hover, .footer.bg-success .footer-link:focus {
  color: #fff;
}

.footer.bg-success .footer-link.disabled {
  color: #7bdcb8 !important;
}

.footer.bg-success .footer-text {
  color: #fff;
}

.footer.bg-success .show > .footer-link,
.footer.bg-success .active > .footer-link,
.footer.bg-success .footer-link.show,
.footer.bg-success .footer-link.active {
  color: #fff;
}

.footer.bg-success hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-info {
  background-color: #28c3d7 !important;
  color: #dcf5f8;
}

.footer.bg-info .footer-link {
  color: #dcf5f8;
}

.footer.bg-info .footer-link:hover, .footer.bg-info .footer-link:focus {
  color: #fff;
}

.footer.bg-info .footer-link.disabled {
  color: #94e1eb !important;
}

.footer.bg-info .footer-text {
  color: #fff;
}

.footer.bg-info .show > .footer-link,
.footer.bg-info .active > .footer-link,
.footer.bg-info .footer-link.show,
.footer.bg-info .footer-link.active {
  color: #fff;
}

.footer.bg-info hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-warning {
  background-color: #FFD950 !important;
  color: #998230;
}

.footer.bg-warning .footer-link {
  color: #998230;
}

.footer.bg-warning .footer-link:hover, .footer.bg-warning .footer-link:focus {
  color: #665720;
}

.footer.bg-warning .footer-link.disabled {
  color: #c2a53d !important;
}

.footer.bg-warning .footer-text {
  color: #665720;
}

.footer.bg-warning .show > .footer-link,
.footer.bg-warning .active > .footer-link,
.footer.bg-warning .footer-link.show,
.footer.bg-warning .footer-link.active {
  color: #665720;
}

.footer.bg-warning hr {
  border-color: rgba(102, 87, 32, 0.0832855);
}

.footer.bg-danger {
  background-color: #d9534f !important;
  color: #f7dbda;
}

.footer.bg-danger .footer-link {
  color: #f7dbda;
}

.footer.bg-danger .footer-link:hover, .footer.bg-danger .footer-link:focus {
  color: #fff;
}

.footer.bg-danger .footer-link.disabled {
  color: #eba5a2 !important;
}

.footer.bg-danger .footer-text {
  color: #fff;
}

.footer.bg-danger .show > .footer-link,
.footer.bg-danger .active > .footer-link,
.footer.bg-danger .footer-link.show,
.footer.bg-danger .footer-link.active {
  color: #fff;
}

.footer.bg-danger hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.footer.bg-dark {
  background-color: #2f3337 !important;
  color: #9d9fa1;
}

.footer.bg-dark .footer-link {
  color: #9d9fa1;
}

.footer.bg-dark .footer-link:hover, .footer.bg-dark .footer-link:focus {
  color: #fff;
}

.footer.bg-dark .footer-link.disabled {
  color: #717477 !important;
}

.footer.bg-dark .footer-text {
  color: #fff;
}

.footer.bg-dark .show > .footer-link,
.footer.bg-dark .active > .footer-link,
.footer.bg-dark .footer-link.show,
.footer.bg-dark .footer-link.active {
  color: #fff;
}

.footer.bg-dark hr {
  border-color: rgba(255, 255, 255, 0.06);
}

.footer.bg-white {
  background-color: #fff !important;
  color: #4E5155;
}

.footer.bg-white .footer-link {
  color: #4E5155;
}

.footer.bg-white .footer-link:hover, .footer.bg-white .footer-link:focus {
  color: #666666;
}

.footer.bg-white .footer-link.disabled {
  color: #959799 !important;
}

.footer.bg-white .footer-text {
  color: #666666;
}

.footer.bg-white .show > .footer-link,
.footer.bg-white .active > .footer-link,
.footer.bg-white .footer-link.show,
.footer.bg-white .footer-link.active {
  color: #666666;
}

.footer.bg-white hr {
  border-color: rgba(102, 102, 102, 0.075);
}

.footer.bg-light {
  background-color: #f1f1f2 !important;
  color: #4E5155;
}

.footer.bg-light .footer-link {
  color: #4E5155;
}

.footer.bg-light .footer-link:hover, .footer.bg-light .footer-link:focus {
  color: #606061;
}

.footer.bg-light .footer-link.disabled {
  color: #8f9194 !important;
}

.footer.bg-light .footer-text {
  color: #606061;
}

.footer.bg-light .show > .footer-link,
.footer.bg-light .active > .footer-link,
.footer.bg-light .footer-link.show,
.footer.bg-light .footer-link.active {
  color: #606061;
}

.footer.bg-light hr {
  border-color: rgba(96, 96, 97, 0.0777227);
}

.footer.bg-lighter {
  background-color: #f8f8f8 !important;
  color: #4E5155;
}

.footer.bg-lighter .footer-link {
  color: #4E5155;
}

.footer.bg-lighter .footer-link:hover, .footer.bg-lighter .footer-link:focus {
  color: #636363;
}

.footer.bg-lighter .footer-link.disabled {
  color: #929496 !important;
}

.footer.bg-lighter .footer-text {
  color: #636363;
}

.footer.bg-lighter .show > .footer-link,
.footer.bg-lighter .active > .footer-link,
.footer.bg-lighter .footer-link.show,
.footer.bg-lighter .footer-link.active {
  color: #636363;
}

.footer.bg-lighter hr {
  border-color: rgba(99, 99, 99, 0.0763725);
}
